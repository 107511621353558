<template>
 <sdPageHeader title="Offerte aanmaken"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <!--           <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">-->
           <!--            <line :x2="(Math.cos(-45 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(-45 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            <line :x2="(Math.cos(0 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(0 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            &lt;!&ndash; If you do not specify the stroke-->
           <!--                 color the line will not be visible &ndash;&gt;-->
           <!--           </svg>-->
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address" :gutter="30">
           <a-col :md="14">
            <sdCards title="Klant selecteren">
             <a-row style="padding: 0 !important;">
              <a-col :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <a-row :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;">
                 <a-col :xl="16" :xs="24"
                        class="p-2 mb-2" style="vertical-align: middle;display: table-cell;align-self: center">
                  {{ globalSearchData.address.street }}
                  {{
                   globalSearchData.address.houseNumber
                  }} {{
                   globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                  }}<br/>
                  {{ globalSearchData.address.postalCode }} {{ globalSearchData.address.city }} <br/>Nederland
                 </a-col>
                 <a-col :xl="8" :xs="24" justify="end"
                        style="vertical-align: middle;align-self: center;text-align: end">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            style="text-wrap: balance;height: auto;"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">
                   Particulier
                  </a-button>
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mb-2 mt-2 w-100"
                            size="large"
                            style="text-wrap: balance;height: auto;"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                   Zakelijk
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.relations.length > 0" justify="center">
              <a-col :sm="24" :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <a-row v-for="relation in globalSearchData.relations" :key="relation.id" :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                 <a-col :xl="18" :xs="24" style="vertical-align: middle;display: table-cell;align-self: center;">
                                                                              <span><strong>{{
                                                                                relation.fullName
                                                                               }}</strong>
                                                                                                                          <span
                                                                                                                           class="status-badge ml-2">{{
                                                                                                                            relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'
                                                                                                                           }}</span>
                                                                              </span><br/>
                  <span>{{ relation.street }} {{
                    relation.houseNumber
                   }} {{
                    relation.houseNumberAddition ? relation.houseNumberAddition : ''
                   }}<br/> {{ relation.postalCode }} {{ relation.city }}<br/> Nederland</span><br/>
                 </a-col>
                 <a-col :xl="6" :xs="24"
                        style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(relation,relation.type)"> Selecteren
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
              <a-col :sm="24" :xs="24" class="mb-2">
               <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                :src="require(`@/static/img/kvk.svg`)"
                alt="" class="ml-5"/></sdHeading>
               <a-row v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber" :gutter="30"
                      style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                <a-col :xl="18" :xs="24"
                       class="p-2" style="vertical-align: middle;display: table-cell;align-self: center">
                                                                                           <span><strong>{{
                                                                                             coc.companyName
                                                                                            }} - {{
                                                                                             coc.companyCocNumber
                                                                                            }}</strong></span><br/>
                 <span>{{ coc.street }} {{
                   coc.houseNumber
                  }} {{
                   coc.houseNumberAddition ? coc.houseNumberAddition : ''
                  }}<br/> {{ coc.postalCode }} {{ coc.city }}<br/>Nederland</span>
                </a-col>
                <a-col :xl="6" :xs="24" justify="end"
                       style="vertical-align: middle;align-self: center;text-align: end">
                 <a-button :disabled="loadingPreFillRelationOnSolarOrder"
                           :loading="loadingPreFillRelationOnSolarOrder"
                           class="mr-2 mb-2 mt-2 w-100"
                           size="large"
                           style="text-wrap: balance;height: auto;"
                           type="primary-outlined"
                           @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Zakelijk
                 </a-button>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
           </a-col>
           <a-col :md="10">
            <sdCards style="text-align: center" title="Locatie">
             <img :src="globalSearchData.streetViewImageFileURL" style="width: 100%;height: auto;border-radius:5px">
            </sdCards>
           </a-col>
          </a-row>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #product>
          <a-row :gutter="30">
           <a-col :md="16">
            <sdCards title="Verbruik">
             <a-row class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Type meterkast</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-form
                ref="meterCupboardForm"
                layout="vertical"
               >
                <a-form-item
                 label="Meter Cupboard"
                 name="meterCupboard"
                >
                 <a-radio-group
                  v-model:value="order.extraData.meterCupboard"
                  button-style="solid"
                  @change="resetSubProducts"
                 >
                  <a-radio-button :value="1">1-fase</a-radio-button>
                  <a-radio-button :value="3">3 fase</a-radio-button>
                 </a-radio-group>
                </a-form-item>
               </a-form>

              </a-col>
             </a-row>
             <a-row v-if="order.extraData.meterCupboard === 1">
              <a-col :xs="24" class="mb-2">
               <label>Aansluiting</label>
              </a-col>
              <a-col
               :md="12"
               :xs="24"
              >
               <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}">
                <a-select v-model:value="order.extraData.capacityCode"
                          :allowClear="true"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option
                  v-for="(item,index) in capacityCodeElectricity1FaseOptions"
                  :key="index"
                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
             </a-row>
             <a-row v-if="order.extraData.meterCupboard === 3">
              <a-col :xs="24" class="mb-2">
               <label>Aansluiting</label>
              </a-col>
              <a-col
               :md="12"
               :xs="24"
              >
               <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}">
                <a-select v-model:value="order.extraData.capacityCode"
                          :allowClear="true"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option
                  v-for="(item,index) in capacityCodeElectricity3FaseOptions"
                  :key="index"
                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
             </a-row>
             <a-row class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Ik weet mijn verbruik</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-radio-group v-model:value="order.extraData.userKnowsConsumption" button-style="solid">
                <a-radio-button :value="true">Ja</a-radio-button>
                <a-radio-button :value="false">
                 Nee
                </a-radio-button>
               </a-radio-group>
              </a-col>
             </a-row>
             <a-row v-if="!order?.extraData?.userKnowsConsumption" class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Wat is uw woningtype?</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-radio-group v-model:value="order.extraData.estimation.buildingType" button-style="solid">
                <a-radio-button value="apartment">Appartement</a-radio-button>
                <a-radio-button value="terracedHouse">Tussenwoning</a-radio-button>
                <a-radio-button value="cornerHouse">Hoekwoning</a-radio-button>
                <a-radio-button value="semidetachedHouse">2 onder 1 kap</a-radio-button>
                <a-radio-button value="detachedHouse"> Vrijstaand</a-radio-button>
               </a-radio-group>
              </a-col>
             </a-row>
             <a-row v-if="!order?.extraData?.userKnowsConsumption" class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Uit hoeveel personen bestaat uw huishouden?</label>
              </a-col>
              <a-col
               :xs="24"
               style="max-width:350px;text-align:center;border: 2px solid #d5d5d5;border-radius: 5px;padding-left:0px;padding-right:0px"
              >
               <EstimateSolarButtonWrapper>
                <button
                 :class="selectedUsagePersons === 1 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                 @mouseenter="usageEstimatorHoverText = '1 persoon'"
                 @mouseleave="usageEstimatorHoverText = ''"
                 @click.prevent="setSelectedPersons(1)"
                >
                 <i
                  class="fas fa-male"
                  style="font-size: 30px;"
                 />
                </button>
                <button
                 :class="selectedUsagePersons === 2 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                 variant="outline-primary"
                 @mouseenter="usageEstimatorHoverText = '2 personen'"
                 @mouseleave="usageEstimatorHoverText = ''"
                 @click.prevent="setSelectedPersons(2)"
                >
                 <i
                  class="fas fa-male"
                  style="font-size: 30px;"
                 /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                />
                </button>
                <button
                 :class="selectedUsagePersons === 3 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                 type="primary"
                 @mouseenter="usageEstimatorHoverText = '3 personen'"
                 @mouseleave="usageEstimatorHoverText = ''"
                 @click.prevent="setSelectedPersons(3)"
                >
                 <i
                  class="fas fa-male"
                  style="font-size: 30px;"
                 /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                />
                </button>
                <button
                 :class="selectedUsagePersons === 4 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                 variant="outline-primary"
                 @mouseenter="usageEstimatorHoverText = '4 personen'"
                 @mouseleave="usageEstimatorHoverText = ''"
                 @click.prevent="setSelectedPersons(4)"
                >
                 <i
                  class="fas fa-male"
                  style="font-size: 30px;"
                 /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                />
                </button>
                <button
                 :class="selectedUsagePersons === 5 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                 variant="outline-primary"
                 @mouseenter="usageEstimatorHoverText = '5 personen'"
                 @mouseleave="usageEstimatorHoverText = ''"
                 @click.prevent="setSelectedPersons(5)"
                >
                 <i
                  class="fas fa-male"
                  style="font-size: 30px;"
                 /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                /><i
                 class="fas fa-male"
                 style="font-size: 30px;"
                />
                </button>
               </EstimateSolarButtonWrapper>
              </a-col>
             </a-row>
             <a-row v-if="!order?.extraData?.userKnowsConsumption" class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Heb je een elektrische auto die thuis oplaadt?</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-radio-group v-model:value="order.extraData.estimation.hasChargingStation" button-style="solid">
                <a-radio-button :value="false"
                                @click="order.extraData.estimation.chargingStationLoadingSessions = 0">
                 Nee
                </a-radio-button>
                <a-radio-button :value="true">Ja</a-radio-button>
               </a-radio-group>
              </a-col>
             </a-row>
             <a-row
              v-if="order?.extraData?.estimation?.hasChargingStation && !order?.extraData?.userKnowsConsumption"
              class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Hoe vaak per maand laad je de auto op?</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-row>
                <a-col :span="12">
                 <a-slider v-model:value="order.extraData.estimation.chargingStationLoadingSessions"
                           :max="100" :min="0"
                           @change="setAmountChargingStationLoadingSessions"/>
                </a-col>
                <a-col :span="4">
                 <a-input v-model:value="order.extraData.estimation.chargingStationLoadingSessions"
                          addon-after="p/m" disabled="true" size="small"
                          style="margin-left: 16px"/>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
             <a-row v-if="!order?.extraData?.userKnowsConsumption" class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Heeft u zonnepanelen?</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-radio-group v-model:value="order.extraData.estimation.hasSolarPanels" button-style="solid">
                <a-radio-button :value="false" @click="order.extraData.estimation.amountOfSolarPanels = 0">
                 Nee
                </a-radio-button>
                <a-radio-button :value="true">Ja</a-radio-button>
               </a-radio-group>
              </a-col>
             </a-row>
             <a-row
              v-if="order?.extraData?.estimation?.hasSolarPanels && !order?.extraData?.userKnowsConsumption"
              class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Hoeveel zonnepanelen heeft u?</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-row>
                <a-col :span="12">
                 <a-slider v-model:value="order.extraData.estimation.amountOfSolarPanels"
                           :max="100" :min="0" @change="setAmountOfSolarPanels"/>
                </a-col>
                <a-col :span="4">
                 <a-input v-model:value="order.extraData.estimation.amountOfSolarPanels"
                          addon-after="panelen" disabled="true"
                          size="small"
                          style="margin-left: 16px"/>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
             <a-row class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Jaarlijks verbruik</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-form ref="usageForm" :model="{
                      electricityUsage: order.extraData.usage.electricity,
                      returnElectricityUsage: order.extraData.usage.returnElectricity,
                      gasUsage: order.extraData.usage.gas,
                    }"
                       layout="vertical">
                <a-row :gutter="30">
                 <a-col :md="12" :xs="24" class="mb-2">
                  <a-form-item
                   :rules="{required: true,message: 'Dit veld is verplicht'}"
                   name="electricityUsage">
                   <a-input v-model:value="order.extraData.usage.electricity" addon-after="kWh"
                            addon-before="Elektriciteit"
                            name="electricityUsage"
                            type="number"
                   />
                  </a-form-item>
                 </a-col>
                 <a-col :md="12" :xs="24" class="mb-2">
                  <a-form-item
                   :rules="{required: true,message: 'Dit veld is verplicht'}"
                   name="returnElectricityUsage">
                   <a-input v-model:value="order.extraData.usage.returnElectricity" addon-after="kWh"
                            addon-before="Teruglevering"
                            name="returnElectricityUsage"
                            type="number"
                   />
                  </a-form-item>
                 </a-col>
                 <a-col :md="12" :xs="24" class="mb-2">
                  <a-form-item
                   :rules="{required: true,message: 'Dit veld is verplicht'}"
                   name="gasUsage">
                   <a-input v-model:value="order.extraData.usage.gas" addon-after="m3" addon-before="Gas"
                            name="gasUsage" type="number"
                   />
                  </a-form-item>
                 </a-col>
                </a-row>
               </a-form>
              </a-col>
             </a-row>
             <a-row class="mb-5">
              <a-col :xs="24" class="mb-2">
               <label>Tarieven</label>
              </a-col>
              <a-col
               :xs="24"
              >
               <a-row :gutter="30">
                <a-col :md="12" :xs="24" class="mb-2">
                 <a-input v-model:value="order.extraData.tariff.old.electricity" addon-after="per kWh"
                          addon-before="Elektriciteitstarief &euro;"
                          type="number"
                 />
                </a-col>
                <a-col :md="12" :xs="24" class="mb-2">
                 <a-input v-model:value="order.extraData.tariff.old.returnElectricity" addon-after="per kWh"
                          addon-before="Teruglevertarief  &euro;"
                          type="number"
                 />
                </a-col>
                <a-col :md="12" :xs="24" class="mb-2">
                 <a-input v-model:value="order.extraData.tariff.old.gas" addon-after="per m3"
                          addon-before="Gastarief  &euro;"
                          type="number"
                 />
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order?.extraData?.meterCupboard" title="Product">
             <a-row class="mb-5">
              <a-col
               :xs="24"
               class="mb-4"
               style="text-align:left;border: 2px solid #dfe0fd;border-radius: 5px;padding:20px"
              >
               <a-row v-for="(p,i) in filteredProducts" :key="i" :gutter="30"
                      style="padding-right:20px;padding-bottom:10px">
                <a-col :xs="16" class="mb-1">
                 <label class="bold">{{ p.name }}</label>
                </a-col>
                <a-col :xs="8" class="mb-1" style="text-align:right">
                 <a-row :gutter="30">
                  <a-col :xs="10">
                   <label class="bold">Prijs:</label>
                  </a-col>
                  <a-col :xs="14">
                   <label style="font-weight: bold; font-size: 110%">&euro;{{ p.subAmount }}</label>
                  </a-col>
                 </a-row>
                </a-col>
                <a-col :xs="24">
                 <span class="status-text"
                       style="color:#3262ff;background-color: #dfe0fd">bruto {{
                   p.description.split('-')[0]
                  }} kWh</span>
                 <span class="status-text ml-4"
                       style="color:#3262ff;background-color: #dfe0fd">netto {{
                   p.description.split('-')[1]
                  }} kWh kWh</span>
                </a-col>
                <a-col :md="16" :xs="24" class="mt-5">
                 <a-button :disabled="disableAddProduct(p.id, i)" style="margin-right: 5px" type="primary"
                           @click="productsUpdated(p.id,'plus')">Toevoegen
                 </a-button>
                 <a-button type="primary" @click="productsUpdated(p.id,'min')">Verwijderen</a-button>
                </a-col>
                <a-col :md="8" :xs="24" class="mt-5">
                 <a-input v-model:value="p.quantity" disabled="true"
                          size="small"
                          style="margin-left: 16px"/>
                </a-col>
                <a-col v-if=" i < filteredProducts.length" :xs="24" class="mt-5 mb-5 p-0" style="padding-left: 0 !important;
    padding-right: 0 !important;">
                 <hr style="border-top: 1px solid #dfe0fd;"/>
                </a-col>
               </a-row>
               <a-row v-if="noProductSelectedWarning" class="w-100">
                <a-col :xs="24">
                 <sdAlerts
                  :closable="false"
                  :outlined="false"
                  :showIcon="true"
                  description="Selecteer minimaal 1 product om verder te kunnen gaan!"
                  message=""
                  type="error"
                 />
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
            <ActionWrapper>
             <div class="step-action-wrap mb-10">
              <div class="step-action-inner">
               <a-row>
                <a-col :xs="24">
                 <div class="steps-action">
                  <sdButton class="btn-prev" type="primary" @click="() => prev()">
                   <sdFeatherIcons size="16" type="arrow-left"/>
                   <span>Vorige</span>
                  </sdButton>
                  <sdButton class="btn-next" type="primary"
                            @click.prevent="goToRelation()">
                   <span>Volgende</span>
                   <sdFeatherIcons size="16" type="arrow-right"/>
                  </sdButton>
                 </div>
                </a-col>
               </a-row>
              </div>
             </div>
            </ActionWrapper>
           </a-col>
           <a-col :md="8">
            <a-card :bordered="false" title="Winkelmandje">
             <template #cover>
              <a-divider type="vertical"/>
              <img
               alt=""
               src="https://cdn.prod.website-files.com/6459f6ee0c7f63a3b1a74a51/64a41d62c0e137bdb5e0961b_tower%20pro%20-1.jpg"
              />
             </template>
             <a-card-meta>
              <template #description>
               <a-row v-if="order.subProducts.length > 0" class="pb-5">
                <a-col :span="24" class="mb-2">
                 <div style="font-size:17px; color:black">Thuisbatterijen</div>
                </a-col>
                <a-col :span="24" class="mb-2">
                 <a-row>
                  <a-col :span="12" class="mb-1" style="text-align:left">
                   <div style="font-size:12px">product</div>
                  </a-col>
                  <a-col :span="12" class="mb-1" style="text-align:right">
                   <div style="font-size:12px">Eenmalige kosten</div>
                  </a-col>
                  {{ subProduct }}
                  <template v-for="(subProduct,i) in order.subProducts" :key="i">
                   <a-col :span="12" class="mb-1">
                    <div style="font-size:14px; color:black">{{ subProduct.quantity }}x {{ subProduct.name }}</div>
                   </a-col>
                   <a-col :span="12" class="mb-1" style="text-align:right">
                    <div style="font-size:14px; color:black">
                     {{
                      new Intl.NumberFormat('nl-NL', {
                       style: 'currency',
                       currency: 'EUR',
                       maximumFractionDigits: 0,
                       minimumFractionDigits: 0,
                      }).format((subProduct.quantity * subProduct.subAmount) ?? 0)
                     }}
                    </div>
                   </a-col>
                  </template>
                 </a-row>
                </a-col>
               </a-row>
               <a-row class="pb-5">
                <a-col v-if="order?.extraData?.savings?.totalSavings" :key="formattedPaybackPeriodKey" :span="24"
                       class="mb-4"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px">
                 <a-statistic :value="new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                 }).format(order?.extraData?.savings?.totalSavings ?? 0)
                " style="margin-right: 50px" title="Totale besparing elekticiteit"/>
                </a-col>
                <a-col v-if="order?.extraData?.savings?.paybackPeriod" :key="formattedPaybackPeriodKey" :span="24"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px;">
                 <a-statistic :value="formattedPaybackPeriod"
                              title="Terugverdientijd batterij"/>
                </a-col>
               </a-row>
              </template>
             </a-card-meta>
            </a-card>
            <a-card :bordered="false"
                    style="background-color: #dfe0fd;margin-top: -35px;border-top-left-radius: 0 !important;border-top-right-radius: 0 !important">

             <a-row>
              <!--              <a-col :span="24" class="mb-2">-->
              <!--               <div style="font-size:20px; font-weight:bold">Eenmalige kosten</div>-->
              <!--              </a-col>-->
              <a-col :span="12">
               <div style="font-size:22px">Totaal <span style="font-size:14px">(excl. btw)</span></div>
              </a-col>
              <a-col :span="12" class="mb-2" style="text-align:right">
               <div style="font-size:22px">
                {{
                 new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                 }).format(order.extraData.batteryPrice ?? 0)
                }}
               </div>
              </a-col>
             </a-row>
            </a-card>
           </a-col>
          </a-row>
         </template>
         <template #relation>
          <a-row :gutter="30">
           <a-col :md="16">
            <Relation
             ref="relationComponentRef"
             :disable-is-business="true"
             :enable-birthday="true"
             :is-order-create="true"
             :maxAge="80"
             :minAge="18"
             :relation="order.relation"
             :show-card-save-button="false"
             :show-footer="false"
             :show-global-save-button="true"
             :showHeader="false"
             :showIBAN="true"
             type="ADD"
             @submit-external="handleRelationOnOrder($event)"
            ></Relation>
            <ActionWrapper>
             <div class="step-action-wrap">
              <div class="step-action-inner">
               <a-row>
                <a-col :xs="24">
                 <div class="steps-action">
                  <sdButton class="btn-prev" type="primary" @click="() => prev()">
                   <sdFeatherIcons size="16" type="arrow-left"/>
                   <span>Vorige</span>
                  </sdButton>
                  <sdButton class="btn-next" type="primary"
                            @click.prevent="$refs.relationComponentRef.submitExternal()">
                   <span>Volgende</span>
                   <sdFeatherIcons size="16" type="arrow-right"/>
                  </sdButton>
                 </div>
                </a-col>
               </a-row>
              </div>
             </div>
            </ActionWrapper>
           </a-col>
           <a-col :md="8">
            <a-card :bordered="false" title="Winkelmandje">
             <template #cover>
              <a-divider type="vertical"/>
              <img
               alt=""
               src="https://cdn.prod.website-files.com/6459f6ee0c7f63a3b1a74a51/64a41d62c0e137bdb5e0961b_tower%20pro%20-1.jpg"
              />
             </template>
             <a-card-meta>
              <template #description>
               <a-row v-if="order.subProducts.length > 0" class="pb-5">
                <a-col :span="24" class="mb-2">
                 <div style="font-size:17px; color:black">Thuisbatterijen</div>
                </a-col>
                <a-col :span="24" class="mb-2">
                 <a-row>
                  <a-col :span="12" class="mb-1" style="text-align:left">
                   <div style="font-size:12px">product</div>
                  </a-col>
                  <a-col :span="12" class="mb-1" style="text-align:right">
                   <div style="font-size:12px">Eenmalige kosten</div>
                  </a-col>
                  <template v-for="(subProduct,i) in order.subProducts" :key="i">
                   <a-col :span="12" class="mb-1">
                    <div style="font-size:14px; color:black">{{ subProduct.quantity }}x {{ subProduct.name }}</div>
                   </a-col>
                   <a-col :span="12" class="mb-1" style="text-align:right">
                    <div style="font-size:14px; color:black">
                     {{
                      new Intl.NumberFormat('nl-NL', {
                       style: 'currency',
                       currency: 'EUR',
                       maximumFractionDigits: 0,
                       minimumFractionDigits: 0,
                      }).format((subProduct.quantity * subProduct.subAmount) ?? 0)
                     }}
                    </div>
                   </a-col>
                  </template>
                 </a-row>
                </a-col>
               </a-row>
               <a-row class="pb-5">
                <a-col v-if="order?.extraData?.savings?.totalSavings" :key="formattedPaybackPeriodKey" :span="24"
                       class="mb-4"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px">
                 <a-statistic :value="new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                 }).format(order?.extraData?.savings?.totalSavings ?? 0)
                " style="margin-right: 50px" title="Totale besparing elekticiteit"/>
                </a-col>
                <a-col v-if="order?.extraData?.savings?.paybackPeriod" :key="formattedPaybackPeriodKey" :span="24"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px;">
                 <a-statistic :value="formattedPaybackPeriod"
                              title="Terugverdientijd batterij"/>
                </a-col>
               </a-row>
              </template>
             </a-card-meta>
            </a-card>
            <a-card :bordered="false"
                    style="background-color: #dfe0fd;margin-top: -35px;border-top-left-radius: 0 !important;border-top-right-radius: 0 !important">

             <a-row>
              <!--              <a-col :span="24" class="mb-2">-->
              <!--               <div style="font-size:20px; font-weight:bold">Eenmalige kosten</div>-->
              <!--              </a-col>-->
              <a-col :span="12">
               <div style="font-size:22px">Totaal <span style="font-size:14px">(excl. btw)</span></div>
              </a-col>
              <a-col :span="12" class="mb-2" style="text-align:right">
               <div style="font-size:22px">
                {{
                 new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                 }).format(order.extraData.batteryPrice ?? 0)
                }}
               </div>
              </a-col>
             </a-row>
            </a-card>
           </a-col>
          </a-row>
         </template>
         <template #summary>
          <a-row :gutter="30">
           <a-col :md="16">
            <Main>
             <a-row :gutter="25" type="flex">
              <a-col :xs="24" style="display: flex;flex-flow: column;">
               <Suspense>
                <template #default>
                 <UserBioBox>
                  <sdCards title="Contactgegevens">
                   <a-row v-if="!order || !order.relation">
                    <a-col :xs="24">
                     <div class="user-info">
                      <a-skeleton active/>
                     </div>
                    </a-col>
                   </a-row>
                   <a-row v-else>
                    <a-col :md="12" :xs="24">
                     <div class="user-info">
                      <ul class="user-info__contact">
                       <li>
                        <template v-if="order.relation.type==='CONSUMER'">
                         <sdFeatherIcons size="14" type="user"/>
                         <span>{{
                           order.relation.gender === 'MALE' ? 'Dhr.' : ''
                          }}{{
                           order.relation.gender === 'FEMALE' ? 'Mevr.' : ''
                          }} {{
                           order.relation.firstName
                          }}{{
                           order.relation.middleName && order.relation.middleName !== '' ? ` ${order.relation.middleName}` : ''
                          }} {{ order.relation.lastName }}</span>
                        </template>
                        <template v-if="order.relation.type==='BUSINESS'">
                         <sdFeatherIcons size="14" type="briefcase"/>
                         <span>{{ order.relation.companyName }}<br/>{{
                           order.relation.companyCocNumber
                          }}</span>
                        </template>
                       </li>
                       <li>
                        <sdFeatherIcons size="14" type="home"/>
                        <span>{{ order.relation.street }} {{
                          order.relation.houseNumber
                         }}{{
                          order.relation.houseNumberAddition && order.relation.houseNumberAddition !== '' ? order.relation.houseNumberAddition : ''
                         }}<br/>{{ order.relation.postalCode }} {{
                          order.relation.city
                         }} {{ order.relation.country === 'NL' ? 'Nederland' : '' }}</span>
                       </li>
                       <li>
                        <sdFeatherIcons size="14" type="mail"/>
                        <span>{{ order.relation.email }}</span>
                       </li>
                       <li>
                        <sdFeatherIcons size="14" type="phone"/>
                        <span>{{ order.relation.phoneNumber }}</span></li>
                       <li v-if="order.relation.bankAccountNumber !== null">
                        <sdFeatherIcons size="14" type="credit-card"/>
                        <span>{{ order.relation.bankAccountNumber }}<br/>{{ order.relation.debtorName }}</span>
                       </li>
                      </ul>
                     </div>
                    </a-col>
                    <a-col :md="12" :xs="24">
                     <div class="user-info">
                      <ul class="user-info__contact">
                       <li>
                        <sdFeatherIcons size="14" type="zap"/>
                        <span>Jaarlijks elektriciteitsverbruik<br/>{{
                          order.extraData.usage.electricity
                         }} kWh</span>
                       </li>
                       <li>
                        <sdFeatherIcons size="14" type="sun"/>
                        <span style="font-weight:bold">Jaarlijks teruglevering<br/>{{
                          order.extraData.usage.returnElectricity
                         }} kWh</span>
                       </li>
                       <li>
                        <sdFeatherIcons size="14" type="droplet"/>
                        <span style="font-weight:bold">Jaarlijks gasverbruik<br/>{{
                          order.extraData.usage.gas
                         }} m3</span>
                       </li>
                      </ul>
                     </div>
                    </a-col>
                   </a-row>
                  </sdCards>
                 </UserBioBox>
                </template>
               </Suspense>
              </a-col>
              <sdCards v-if="order?.extraData?.meterCupboard" title="Producten">
               <a-row class="mb-5">
                <a-col
                 :xs="24"
                 class="mb-4"
                 style="text-align:left;border: 2px solid #dfe0fd;border-radius: 5px;padding:20px"
                >
                 <template v-for="(p,i) in filteredProducts" :key="i">
                  <a-row v-if="p.quantity>0"
                         :gutter="30"
                         style="padding-right:20px;padding-bottom:10px">
                   <a-col :xs="16" class="mb-1">
                    <label class="bold">{{ p.name }}</label>
                   </a-col>
                   <a-col :xs="8" class="mb-1" style="text-align:right">
                    <a-row :gutter="30">
                     <a-col :xs="10">
                      <label class="bold">Prijs:</label>
                     </a-col>
                     <a-col :xs="14">
                      <label style="font-weight: bold; font-size: 110%">&euro;{{ p.subAmount }}</label>
                     </a-col>
                    </a-row>
                   </a-col>
                   <a-col :xs="24">
                 <span class="status-text"
                       style="color:#3262ff;background-color: #dfe0fd">bruto {{
                   p.description.split('-')[0]
                  }} kWh</span>
                    <span class="status-text ml-4"
                          style="color:#3262ff;background-color: #dfe0fd">netto {{
                      p.description.split('-')[1]
                     }} kWh kWh</span>
                   </a-col>
                   <!--                   <a-col :md="16" :xs="24">-->
                   <!--                    <a-button :disabled="isFase3QuantityExceeded" style="margin-right: 5px" type="primary"-->
                   <!--                              @click="productsUpdated(p.id,'plus')">Toevoegen-->
                   <!--                    </a-button>-->
                   <!--                    <a-button type="primary" @click="productsUpdated(p.id,'min')">Verwijderen</a-button>-->
                   <!--                    <a-slider v-if="p.externalReference === 1" v-model:value="p.quantity" :max="1" :min="0"-->
                   <!--                              disabled="true"-->
                   <!--                              @change="productsUpdated"/>-->
                   <!--                    <a-slider v-if="p.externalReference === 1" v-model:value="p.quantity" :max="3" :min="0"-->
                   <!--                              disabled="true"-->
                   <!--                              @change="productsUpdated"/>-->
                   <!--                   </a-col>-->
                   <a-col :md="8" :xs="24">
                    <a-input v-model:value="p.quantity" disabled="true"
                             size="small"
                             style="margin-left: 16px"/>
                   </a-col>
                  </a-row>
                 </template>

                </a-col>
               </a-row>
              </sdCards>
             </a-row>
             <a-row v-if="order.flowQuestions && order.flowQuestions.length > 0" :gutter="25">
              <a-col :xs="24">
               <sdCards title="Product vragen">
                <a-form ref="flowQuestionsForm" :model="{flowQuestions:order.flowQuestions}"
                        layout="vertical">
                 <a-row :gutter="25">
                  <a-col v-for="(item,index) in order.flowQuestions" :key="index" :md="12" :xs="24">
                   <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="{required: true,message: 'Dit veld is verplicht'}">
                    <DatePickerWrapper>
                     <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                                    :disabled-date="disabledStartDate"
                                    :format="dateFormat" class="w-100"/>
                    </DatePickerWrapper>
                   </a-form-item>
                   <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="{required: true,message: 'Dit veld is verplicht'}">
                    <DatePickerWrapper>
                     <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()" :format="dateFormat"
                                    class="w-100"/>
                    </DatePickerWrapper>
                   </a-form-item>
                   <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="{required: true,message: 'Dit veld is verplicht'}">
                    <a-input v-model:value="item.value" placeholder=""/>
                   </a-form-item>
                   <a-form-item v-if="item.inputType === 'INTEGER' && item.type !== 'PANELCOUNT'"
                                :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                    <a-input v-model:value="item.value" placeholder="" step="1" type="text"/>
                   </a-form-item>
                   <a-form-item v-if="item.inputType === 'INTEGER' && item.type === 'PANELCOUNT'"
                                :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                    <a-input v-model:value="item.value" :disabled="true" placeholder="" step="1" type="text"/>
                   </a-form-item>
                   <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                                :name="['flowQuestions', index, 'value']"
                                :rules="{required: true,message: 'Dit veld is verplicht'}">
                    <a-select v-model:value="item.value" :allowClear="true" class="sDash_fullwidth-select"
                              size="large">
                     <a-select-option v-for="(option,index) in item.inputValues"
                                      :key="index"
                                      :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
                     </a-select-option>
                    </a-select>
                   </a-form-item>
                  </a-col>
                 </a-row>
                </a-form>
               </sdCards>
              </a-col>
             </a-row>
             <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
              <a-col :xs="24">
               <sdCards title="Ondertekenen">
                <a-row :gutter="25">
                 <a-col :xs="12" class="signature align-center">
                  <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'"
                                 :sigOption="sigState.option"
                                 :w="'450px'"></Vue3Signature>
                  <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                   <div role="alert">Uw handtekening is vereist</div>
                  </div>
                  <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                   <span>Opnieuw</span>
                  </sdButton>
                 </a-col>
                 <a-col :xs="12" class="mb-5">
                  <a-form ref="customerApprovalsForm" :model="order"
                          layout="vertical">
                   <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                                :name="['product','customerApprovalsValidation', index]"
                                :rules="{ validator: checkCheckBox }" class="checkbox-group"
                                label=""
                                style="padding-bottom:0px;margin-bottom:0px">
                    <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                                :name="['product','customerApprovalsValidation', index]">
                     {{ item }}
                    </a-checkbox>
                   </a-form-item>
                  </a-form>
                 </a-col>
                </a-row>
               </sdCards>
              </a-col>
             </a-row>
            </Main>
            <ActionWrapper v-if="!isFinished">
             <div class="step-action-wrap">
              <div class="step-action-inner">
               <a-row>
                <a-col :xs="24">
                 <div class="steps-action">
                  <sdButton class="btn-prev" type="light" @click="() => prev()">
                   <sdFeatherIcons size="16" type="arrow-left"/>
                   <span>Terug</span>
                  </sdButton>
                  <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                            type="primary"
                            @click="handleCreateOrder"> Aanmaken
                  </a-button>
                 </div>
                </a-col>
               </a-row>
              </div>
             </div>
            </ActionWrapper>
           </a-col>
           <a-col :md="8">
            <a-card :bordered="false" title="Winkelmandje">
             <template #cover>
              <a-divider type="vertical"/>
              <img
               alt=""
               src="https://cdn.prod.website-files.com/6459f6ee0c7f63a3b1a74a51/64a41d62c0e137bdb5e0961b_tower%20pro%20-1.jpg"
              />
             </template>
             <a-card-meta>
              <template #description>
               <a-row v-if="order.subProducts.length > 0" class="pb-5">
                <a-col :span="24" class="mb-2">
                 <div style="font-size:17px; color:black">Thuisbatterijen</div>
                </a-col>
                <a-col :span="24" class="mb-2">
                 <a-row>
                  <a-col :span="12" class="mb-1" style="text-align:left">
                   <div style="font-size:12px">product</div>
                  </a-col>
                  <a-col :span="12" class="mb-1" style="text-align:right">
                   <div style="font-size:12px">Eenmalige kosten</div>
                  </a-col>
                  <template v-for="(subProduct,i) in order.subProducts" :key="i">
                   <a-col :span="12" class="mb-1">
                    <div style="font-size:14px; color:black">{{ subProduct.quantity }}x {{ subProduct.name }}</div>
                   </a-col>
                   <a-col :span="12" class="mb-1" style="text-align:right">
                    <div style="font-size:14px; color:black">
                     {{
                      new Intl.NumberFormat('nl-NL', {
                       style: 'currency',
                       currency: 'EUR',
                       maximumFractionDigits: 0,
                       minimumFractionDigits: 0,
                      }).format((subProduct.quantity * subProduct.subAmount) ?? 0)
                     }}
                    </div>
                   </a-col>
                  </template>
                 </a-row>
                </a-col>
               </a-row>
               <a-row class="pb-5">
                <a-col v-if="order?.extraData?.savings?.totalSavings" :key="formattedPaybackPeriodKey" :span="24"
                       class="mb-4"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px">
                 <a-statistic :value="new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                 }).format(order?.extraData?.savings?.totalSavings ?? 0)
                " style="margin-right: 50px" title="Totale besparing elekticiteit"/>
                </a-col>
                <a-col v-if="order?.extraData?.savings?.paybackPeriod" :key="formattedPaybackPeriodKey" :span="24"
                       style="text-align:center;border: 2px solid #dfe0fd;border-radius: 5px;padding: 10px;">
                 <a-statistic :value="formattedPaybackPeriod"
                              title="Terugverdientijd batterij"/>
                </a-col>
               </a-row>
              </template>
             </a-card-meta>
            </a-card>
            <a-card :bordered="false"
                    style="background-color: #dfe0fd;margin-top: -35px;border-top-left-radius: 0 !important;border-top-right-radius: 0 !important">

             <a-row>
              <!--              <a-col :span="24" class="mb-2">-->
              <!--               <div style="font-size:20px; font-weight:bold">Eenmalige kosten</div>-->
              <!--              </a-col>-->
              <a-col :span="12">
               <div style="font-size:22px">Totaal <span style="font-size:14px">(excl. btw)</span></div>
              </a-col>
              <a-col :span="12" class="mb-2" style="text-align:right">
               <div style="font-size:22px">
                {{
                 new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                 }).format(order.extraData.batteryPrice ?? 0)
                }}
               </div>
              </a-col>
             </a-row>
            </a-card>
           </a-col>
          </a-row>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 EstimateSolarButtonWrapper,
 FigureWizards,
 NotFoundWrapper,
 OrderSummary,
 PaginationWrapper,
 ProductCard,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {
 AutoCompleteShopStyled,
 AutoCompleteStyled,
 BasicFormWrapper,
 DatePickerWrapper,
 Main,
 ModalMain,
 TopToolBox
} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import FlowCards from "./component/FlowCards";
import {
 capacityCodeElectricity1FaseOptions,
 capacityCodeElectricity3FaseOptions,
 defaultOptions,
} from "../../utility/enums";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import ConnectionTable from "./component/ConnectionTable";
import RelationSVMSummaryCard from "./component/RelationSVMSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRoute, useRouter} from "vue-router";
import ProductSummaryInfoCard from "@/view/orders/component/ProductSummaryInfoCard";
import ProductInfoCard from "@/view/orders/component/ProductInfoCard";
import ProductCardsShop from "@/view/orders/component/ProductCardsShop";
import FiltersShop from "@/view/orders/component/FiltersShop";
import ShoppingCard from "@/view/orders/component/ShoppingCard";
import {InfoCircleOutlined} from "@ant-design/icons-vue";
import {formatYearsToYearsAndMonths} from "../../utility/utility";
import {UserBioBox} from "./component/style";

const productTableColumns = [
 {
  title: 'Product',
  dataIndex: 'product',
  key: 'product',
 },
 {
  title: 'Aantal',
  dataIndex: 'quantity',
  key: 'quantity',
 },
 {
  title: 'Prijs',
  dataIndex: 'total',
  key: 'total',
  width: '150px'
 },
 {
  title: '',
  dataIndex: 'action',
  key: 'action',
  width: '50px'
 },
];

const OrderCreate = {
 name: 'ShopBattery',
 components: {
  EstimateSolarButtonWrapper,
  InfoCircleOutlined,
  ConnectionTable,
  RelationSVMSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ConnectionCardWrapper,
  RelationCard,
  ActionWrapper,
  EstimateButtonWrapper,
  FlowCards,
  DatePickerWrapper,
  ProductCard,
  ProductSummaryInfoCard,
  ProductInfoCard,
  TopToolBox,
  NotFoundWrapper,
  PaginationWrapper,
  ProductCardsShop,
  FiltersShop,
  AutoCompleteShopStyled,
  ShoppingCard,
  AutoCompleteStyled,
  UserBioBox,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'SHOP',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const {params} = useRoute();
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const defaultPageSize = ref(12);
  const relationComponentRef = ref();
  const productFilters = reactive(computed(() => state.shop.productFilters));
  const currentPageProduct = reactive(computed(() => state.shop.currentPageProduct));
  const productCount = reactive(computed(() => state.shop.productCount));
  const toProduct = reactive(computed(() => state.shop.toProduct));
  const fromProduct = reactive(computed(() => state.shop.fromProduct));
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const products = reactive(computed(() => state.shop.products));
  const loadingFetchShopProducts = reactive(computed(() => state.shop.loadingFetchShopProducts));
  const batteryOrderKey = ref();
  const order = computed(() => {
   const order = state.shop.order;
   batteryOrderKey.value = Math.random();
   return order;
  });
  const loadingCreateOrder = computed(() => state.shop.loadingCreateOrder);
  const loadingPreFillRelationOnShopOrder = computed(() => state.shop.loadingPreFillRelationOnShopOrder);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const innerWidth = ref(window.innerWidth);
  const noProductSelectedWarning = ref(false);
  const subProducts = reactive(computed(() => state.shop.subProducts));
  const flowQuestionsForm = ref();
  const usageForm = ref();
  const meterCupboardForm = ref();
  const selectedComponent = reactive({
   selectedComponent: null,
  });

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'SHOP',
   });
   dispatch('resetShopOrder');
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   await dispatch('resetShopOrder');
   await dispatch('resetRelationShopOrder');
   await dispatch('preFillRelationOnShopOrder', {value: relation, stepRoute: next});
  };

  const handleRelationOnOrder = async (data) => {
   await dispatch('addRelationToShopOrder', {value: data, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = async () => {
   const orderValidators = []
   signatureError.value = false;
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }
   if (flowQuestionsForm.value) {
    orderValidators.push(flowQuestionsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureShopData', signature1.value.save('image/svg+xml'))
      dispatch('createShopOrder', {
       successRoute: orderCreateSuccessRoute,
       productID: params.productID,
       addons: [],
       subProducts: filteredProducts.value,
      })
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createShopOrder', {
      successRoute: orderCreateSuccessRoute,
      productID: params.productID,
      addons: [],
      subProducts: filteredProducts.value ? filteredProducts.value.filter(product => product.quantity >= 1) : [],
     })
    }
   })
    .catch(() => dispatch('orderCreateShopValidationError'))
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  let debounceProductSearch = null;
  const onProductSearch = (value) => {
   clearTimeout(debounceProductSearch)
   debounceProductSearch = setTimeout(async () => {
    await dispatch('setProductFilters', {
     q: value.target.value,
     type: 'search'
    })
    await dispatch('fetchShopProducts', {
     productID: params.productID,
     categoryID: productFilters.value.categoryID,
     priceFrom: productFilters.value.priceFrom,
     priceTill: productFilters.value.priceTill,
     q: productFilters.value.q,
    });
   }, 500);
  }

  const onHandleChangePagination = value => {
   dispatch('fetchShopProducts', {
    productID: params.productID,
    categoryID: productFilters.value.categoryID,
    priceFrom: productFilters.value.priceFrom,
    priceTill: productFilters.value.priceTill,
    q: productFilters.value.q,
    page: value
   });
  }

  const goToRelation = () => {
   noProductSelectedWarning.value = false;
   Promise.all([usageForm.value.validate()]).then(() => {
    if (products.value.some(product => product.quantity > 0)) {
     next()
    } else {
     noProductSelectedWarning.value = true
    }
   });
  }

  const removeProduct = (index) => {
   dispatch('removeSubProduct', index);
  }

  const setProductQuantity = data => {
   dispatch('setSubProductQuantity', data);
  }

  const productTableData = computed(() =>
   subProducts.value.data.map((product, index) => {
    const {id, imageFileURL, name, quantity, totalAmount, externalReference} = product;
    return {
     key: id,
     product: (
      <div class="cart-single">
       <FigureCart>
        {imageFileURL !== null ? (
         <img width="80" height="auto" src={imageFileURL} alt=""/>
        ) : (
         <img width="80" alt="" style="padding-right:5px"
              src="https://shop.eismann.nl/static/de.eismann.shop.theme/dist/img/product/no-image-nl.jpg"/>
        )}
        <figcaption style="width:100%">
         <div className="cart-single__info">
          {externalReference !== null ? (
           <div>
            <span><small>Artikelnr.: {externalReference}</small></span><br/>
            <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
           </div>
          ) : (
           <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
          )}
         </div>
        </figcaption>
       </FigureCart>
      </div>
     ),
     quantity: (
      <div class="cart-single-quantity" style="min-width: 110px;">
       {quantity < 2 ? (
        <sdButton disabled="disabled" class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       ) : (
        <sdButton class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       )}
       <span style="margin:0 10px">{quantity}</span>
       <sdButton class="btn-inc" type="default" onClick={() => setProductQuantity({index: index, type: 'plus'})}>
        <sdFeatherIcons type="plus" size="12"/>
       </sdButton>
      </div>
     ),
     total: <span class="cart-single-t-price">{new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR'
     }).format((parseInt(quantity) * parseFloat(totalAmount)))}</span>,
     action: (
      <div class="table-action">
       <sdButton
        class="btn-icon"
        to="#"
        size="default"
        type="danger"
        shape="circle"
        transparented
        onClick={() => removeProduct(index)}
       >
        <sdFeatherIcons type="trash-2" size={16}/>
       </sdButton>
      </div>
     ),
    };
   }),
  );

  const filteredProducts = ref([]);
  const selectedProducts = ref([]);

  onMounted(async () => {
   let queryString = window.location.search;
   let urlParams = new URLSearchParams(queryString);
   await dispatch('resetShopOrder');
   await dispatch("cleanGlobalSearch");

   if (urlParams.has('type') && (urlParams.get('type') === 'BATTERYADVANCED')) {
    await dispatch('setOrderSubTypeShop', urlParams.get('type'));
   } else {
    router.push(`/order/flow`);
   }

   await dispatch('fetchShopProducts', {productID: params.productID});
   await dispatch('fetchShopCategories', {productID: params.productID});
   await dispatch('fetchShopFlowQuestions', {productID: params.productID});

   filteredProducts.value = products.value.filter(
    item => item.externalReference === String(1)
   );
  });

  const value1 = ref('a')
  const inputValue1 = ref(1)

  const selectedUsagePersons = ref(0);

  const setSelectedPersons = (data) => {
   dispatch('setSelectedPersons', data)
   selectedUsagePersons.value = data;
  };

  const setAmountOfSolarPanels = (data) => {
   dispatch('setAmountOfSolarPanels', data)
  };

  const setAmountChargingStationLoadingSessions = (data) => {
   dispatch('setAmountChargingStationLoadingSessions', data)
  };

  const consumerEstimatorData = {
   "1": {
    "apartment": {
     "e": "1645",
     "g": "719"
    },
    "terracedHouse": {
     "e": "2021",
     "g": "1126"
    },
    "cornerHouse": {
     "e": "2038",
     "g": "1320"
    },
    "semidetachedHouse": {
     "e": "2145",
     "g": "1470"
    },
    "detachedHouse": {
     "e": "2395",
     "g": "1859"
    }
   },
   "2": {
    "apartment": {
     "e": "2079",
     "g": "823"
    },
    "terracedHouse": {
     "e": "2652",
     "g": "1265"
    },
    "cornerHouse": {
     "e": "2737",
     "g": "1489"
    },
    "semidetachedHouse": {
     "e": "2883",
     "g": "1572"
    },
    "detachedHouse": {
     "e": "3266",
     "g": "2036"
    }
   },
   "3": {
    "apartment": {
     "e": "2272",
     "g": "943"
    },
    "terracedHouse": {
     "e": "2967",
     "g": "1335"
    },
    "cornerHouse": {
     "e": "3087",
     "g": "1630"
    },
    "semidetachedHouse": {
     "e": "3375",
     "g": "1623"
    },
    "detachedHouse": {
     "e": "3701",
     "g": "2075"
    }
   },
   "4": {
    "apartment": {
     "e": "2562",
     "g": "1047"
    },
    "terracedHouse": {
     "e": "3809",
     "g": "1474"
    },
    "cornerHouse": {
     "e": "4135",
     "g": "1743"
    },
    "semidetachedHouse": {
     "e": "4236",
     "g": "1673"
    },
    "detachedHouse": {
     "e": "4863",
     "g": "2154"
    }
   },
   "5": {
    "apartment": {
     "e": "2610",
     "g": "1064"
    },
    "terracedHouse": {
     "e": "4124",
     "g": "1590"
    },
    "cornerHouse": {
     "e": "4368",
     "g": "1884"
    },
    "semidetachedHouse": {
     "e": "4605",
     "g": "1809"
    },
    "detachedHouse": {
     "e": "5298",
     "g": "2252"
    }
   }
  }

  watch(
   [
    () => order.value.extraData.estimation.persons,
    () => order.value.extraData.estimation.buildingType,
    () => order.value.extraData.estimation.amountOfSolarPanels,
    () => order.value.extraData.estimation.chargingStationLoadingSessions,
   ],
   (
    [newPersons, newBuildingType, newAmountOfSolarPanels, newChargingStationLoadingSessions],
    [oldPersons, oldBuildingType, oldAmountOfSolarPanels, oldChargingStationLoadingSessions]
   ) => {
    // Return early if both persons and buildingType are missing]
    if ((!newPersons || !oldPersons) && (!newBuildingType || !oldBuildingType)) {
     return;
    }

    // Use old values if new values are null or undefined
    const persons = newPersons ?? oldPersons;
    const buildingType = newBuildingType ?? oldBuildingType;
    const amountOfSolarPanels = newAmountOfSolarPanels ?? oldAmountOfSolarPanels;
    const chargingStationLoadingSessions = newChargingStationLoadingSessions ?? oldChargingStationLoadingSessions;


    const estimationDataConsumer = JSON.parse(JSON.stringify(consumerEstimatorData));
    let e = 0;
    let g = 0;
    let eReturn = 0;

    e = +estimationDataConsumer[+persons][buildingType].e;
    g = +estimationDataConsumer[+persons][buildingType].g;

    // Include charging station loading sessions if available
    if (chargingStationLoadingSessions) {
     e += chargingStationLoadingSessions * 52 * 12;
    }

    // Include solar panel contribution if available
    if (amountOfSolarPanels !== null) {
     eReturn = eReturn + (amountOfSolarPanels * 350);
    }

    dispatch('setOrderExtraDataUsage', {
     usage: {
      electricity: e,
      gas: g,
      returnElectricity: eReturn,
     },
    });
   }
  );


  watch(
   () => order.value.extraData.meterCupboard,
   (newMeterCupboard, oldMeterCupboard) => {
    filteredProducts.value = products.value.filter(
     item => item.externalReference === String(newMeterCupboard ?? oldMeterCupboard)
    );
   },
   {immediate: true} // Run immediately on component load
  );

  const productsUpdated = (id, type) => {
   const index = products.value.findIndex(item => item.id === id);
   if (index !== -1) {
    if (type === 'plus') {
     products.value[index].quantity = products.value[index].quantity + 1;
    } else if (type === 'min' && products.value[index].quantity > 0) {
     products.value[index].quantity = products.value[index].quantity - 1;
    }
   }
   dispatch('batterySavingsCalculation', {
    products: products.value,
   });
  };

  const resetSubProducts = () => {
   const p = products.value
    .map(item => {
     item.quantity = 0;
     return item;
    });

   order.value.extraData.capacityCode = '';
   order.value.extraData.savings.totalSavings = null;
   order.value.extraData.savings.paybackPeriod = null;
   order.value.extraData.batteryCapacity = 0;
   order.value.extraData.batteryPrice = 0;

   filteredProducts.value = p.filter(
    item => item.externalReference === String(order.value.extraData.meterCupboard)
   );
   order.value.subProducts = [];
   dispatch('resetSubProducts');
  }

  watch(
   [
    () => order.value.extraData.usage.gas,
    () => order.value.extraData.usage.electricity,
    () => order.value.extraData.usage.returnElectricity,
   ],
   () => {
    productsUpdated();
   }
  );


  const formattedPaybackPeriodKey = ref(Math.random());
  const formattedPaybackPeriod = computed(() => {
   const data = order.value.extraData.savings.paybackPeriod;
   const formattedPeriod = formatYearsToYearsAndMonths(data);
   formattedPaybackPeriodKey.value = Math.random();
   return formattedPeriod;
  });

  const disableAddProduct = (id, index) => {
   const otherProductHasQuantity = filteredProducts.value.some(p => {
    return p.id !== id && p.quantity > 0;
   });

   if (otherProductHasQuantity) {
    return true;
   } else {
    return filteredProducts.value[index].quantity >= +order.value.extraData.meterCupboard;
   }
  };

  return {
   disableAddProduct,
   meterCupboardForm,
   formattedPaybackPeriodKey,
   formattedPaybackPeriod,
   productsUpdated,
   selectedProducts,
   filteredProducts,
   setAmountOfSolarPanels,
   setAmountChargingStationLoadingSessions,
   setSelectedPersons,
   selectedUsagePersons,
   inputValue1,
   value1,
   products,
   innerWidth,
   sigState,
   steps: [
    {
     title: "Adres",
     content: "address",
    },
    {
     title: "Verbruik & Product",
     content: "product",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Afronden",
     content: "summary",
    },
   ],
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   relationComponentRef,
   defaultOptions,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingPreFillRelationOnShopOrder,
   prevEan,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   loadingHouseNumberAdditions,
   selectedComponent,
   rulesAddComponent,
   loadingFetchShopProducts,
   integerValidator,
   debounceProductSearch,
   productFilters,
   onProductSearch,
   productCount,
   currentPageProduct,
   toProduct,
   fromProduct,
   defaultPageSize,
   onHandleChangePagination,
   goToRelation,
   noProductSelectedWarning,
   productTableColumns,
   productTableData,
   subProducts,
   flowQuestionsForm,
   capacityCodeElectricity1FaseOptions,
   capacityCodeElectricity3FaseOptions,
   formatYearsToYearsAndMonths,
   usageForm,
   resetSubProducts,
  };
 },
};

export default OrderCreate;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
